<template>
  <div>
    <v-list
      v-if="entryList.length"
      :max-height="maxHeight"
      class="overflow-y-auto"
    >
      <v-list-item
        v-for="entry in entryList"
        :key="entry.id"
        :to="getRoute(entry)"
      >
        <v-list-item-avatar>
          <v-img
            v-if="entry.avatar_thumbnail_url"
            :src="entry.avatar_thumbnail_url"
          />
          <v-img
            v-else-if="entry.image_thumbnail_url"
            :src="entry.image_thumbnail_url"
          />
          <v-img
            v-else-if="entry.image"
            :src="entry.image"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-row align="center">
            <v-col>
              <v-list-item-title v-text="entry.user || entry.name" />
              <v-list-item-subtitle
                v-if="challenge"
                v-text="entry.current_level.display_name"
              />
            </v-col>
            <v-col
              v-if="isTeam"
              class="text-right"
              cols="auto"
            >
              <div>
                {{ entry.quests_completed }}
              </div>
              <div class="points-label">
                quests completed
              </div>
            </v-col>
            <v-col
              v-else
              class="text-right"
              cols="auto"
            >
              <div>
                {{ entry.points_earned }}
              </div>
              <div class="points-label">
                points
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="loadMore">
        <v-btn
          v-if="season"
          :to="{ name: 'Season', params: { slug: season.slug }}"
          :block="true"
          color="primary"
        >
          View Seasonal Leaders
        </v-btn>
        <v-btn
          v-else
          :to="{name: 'Leaderboard'}"
          :block="true"
          color="primary"
        >
          View All Leaders
        </v-btn>
      </v-list-item>
    </v-list>
    <v-card v-else>
      <v-card-text>
        No leaders found. {{ boardName }} needs a champion.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    entryList: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '',
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Object,
      required: false,
      default: null,
    },
    region: {
      type: Object,
      required: false,
      default: null,
    },
    challenge: {
      type: Object,
      required: false,
      default: null,
    },
    isTeam: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boardName() {
      if (this.season) {
        return this.season.name
      } else if (this.region) {
        return this.region.name
      } else if (this.challenge) {
        return this.challenge.name
      } else {
        return 'Quest'
      }
    },
  },
  methods: {
    getRoute (entry) {
      this.$store.dispatch('authors/loadNextPage')
        if (entry.user) {
          return {name: 'SocialProfile', params: { username: entry.user }}
        } else if (this.isTeam) {
          return {name: 'Team', params: { id: entry.id }}
        } else {
          return {name: 'Region', params: { slug: entry.slug }}
        }
    },
  },
}
</script>
